class MenuDropdown {
    handleDropdownMouseEnter() {
        this.classList.add('show');
        const dropdownMenu = this.querySelector('.dropdown-menu');
        if (dropdownMenu) {
            dropdownMenu.classList.add('show');
        }
    }

    handleDropdownMouseLeave() {
        this.classList.remove('show');
        const dropdownMenu = this.querySelector('.dropdown-menu');
        if (dropdownMenu) {
            dropdownMenu.classList.remove('show');
        }
    }

    handleDropdownClick(event) {
        // Check for the screen width before processing the click
        if (window.innerWidth >= 1024 && this.classList.contains('show')) {
            const a = this.querySelector('a.nav-link.dropdown-toggle');
            if (a && a.getAttribute('href')) {
                location.href = a.getAttribute('href');
            }
        }
        event.preventDefault(); // Prevent the default click behavior
    }
}

export default new MenuDropdown();