// Bootstrap
require('bootstrap');

// Plugins
import Splide from '@splidejs/splide';

// Components
import MenuDropdown from "./components/MenuDropdown";
import Form from "./components/FormComponents";
import Tab from "./components/TabComponent";

document.addEventListener('DOMContentLoaded', () => {
    const initializeDropdowns = () => {
        document.querySelectorAll('li.nav-item.dropdown').forEach(dropdown => {
            dropdown.addEventListener('mouseenter', MenuDropdown.handleDropdownMouseEnter);
            dropdown.addEventListener('mouseleave', MenuDropdown.handleDropdownMouseLeave);
        });
    };

    const initializeSplideSlider = (sliderId, options) => {
        if (null != document.getElementById(sliderId)) {
            new Splide(`#${sliderId}`, options).mount();
        }
    };

    const initializeModalSlider = (modalSlider, buttons) => {
        const slider = new Splide(`#${modalSlider}`, {
            perPage: 1,
            type: 'fade',
            rewind: true,
            speed: 400,
            pagination: false,
        }).mount();

        buttons.forEach(button => {
            button.addEventListener('click', () => {
                slider.go(Number(button.dataset.index));
            });
        });
    };

    const initializeTabs = () => {
        if (null != document.querySelector('.information-page') || null != document.querySelector('.gallery-page')) {
            Tab.nestedTabSelect('ul.tabs', 'active');
        }
    };

    // Initialize nav dropdowns
    initializeDropdowns();

    // Initialize tabs
    initializeTabs();

    const modalButtons = document.querySelectorAll('[data-bs-toggle="modal"]');

    // Home
    initializeSplideSlider('home-judgment-splide', {
        perPage: 1,
        rewind: true,
        breakpoints: {
            992: {pagination: false},
        },
    });

    initializeSplideSlider('home-gallery-splide', {
        gap: 25,
        perPage: 4,
        focus: 0,
        omitEnd: true,
        breakpoints: {
            1200: {perPage: 4},
            992: {perPage: 3},
            480: {perPage: 2},
        },
    });

    if (null != document.getElementById('home-splide-modal')) {
        // Initialize home modal slider
        initializeModalSlider('home-splide-modal', modalButtons);
    }

    // Intervention
    if (null != document.getElementById('intervention-splide-modal')) {
        // Initialize intervention modal slider
        initializeModalSlider('intervention-splide-modal', modalButtons);
    }

    // About us
    if (null != document.getElementById('aboutUsSection')) {
        document.querySelectorAll('#aboutUsSection .splide').forEach((slider, index) => {
            initializeSplideSlider(`about-us-slider-${index}`, {
                gap: 25,
                perPage: 5,
                focus: 0,
                omitEnd: true,
                pagination: false,
                breakpoints: {
                    1200: {perPage: 4},
                    992: {perPage: 3},
                    480: {perPage: 2},
                },
            });
        });
    }

    if (null != document.getElementById('about-us-splide-modal')) {
        // Initialize about us modal slider
        initializeModalSlider('about-us-splide-modal', modalButtons);
    }

    // Gallery
    if (null != document.getElementById('breast-augmentation-splide-modal')) {
        // Initialize gallery modal slider
        initializeModalSlider('breast-augmentation-splide-modal', modalButtons);
    }

    // Footer - Contact form
    const form = document.getElementById('contact');
    if (null != form) {
        // Add a change event listener to the element with id 'contactConsent'
        document.getElementById('contactConsent').addEventListener('change', function () {
            const consent = document.querySelector('input[id="contactConsent"]:checked');

            if (consent) {
                Form.removeSubmitDisabled();
            } else {
                Form.submitDisabled();
            }
        });

        // Add a change event listener to the 'submit'
        form.addEventListener('submit', function (event) {
            event.preventDefault();
            let form_was_valid = false;

            if (form.checkValidity() === true) {
                // Check if the email and email_check fields have matching values
                const email = document.getElementById('email').value;
                const emailCheck = document.getElementById('email_check').value;
                if (email === emailCheck) {
                    form_was_valid = true;
                } else {
                    document.getElementById('email_check').value = '';
                    // Handle a case where email and email_check do not match
                    alert('Email and Email Check must have the same value');
                    form_was_valid = false;
                }
            }

            if (form_was_valid) {
                // Disable the form
                Form.submitDisabled();
                let formData = new FormData(this);

                fetch("/tools/contact", {
                    method: "POST",
                    body: formData,
                })
                    .then((response) => {
                        if (response.ok) {
                            return response.json();
                        } else if (response.status === 422) {
                            return response.json().then((data) => {
                                throw new Error(JSON.stringify(data));
                            });
                        } else {
                            throw new Error("Failed to submit the form.");
                        }
                    })
                    .then(() => {
                        document.querySelector("footer").scrollIntoView();
                        document.getElementById('contact').style.display = 'none';
                        document.querySelector('.from-title-container').style.display = 'none';
                        document.getElementById('success').classList.add('show');
                    })
                    .catch((error) => {
                        Form.removeSubmitDisabled();
                        alert(error.message);
                    });
            } else {
                form.classList.add('was-validated');
            }
        }, false);
    }
});
