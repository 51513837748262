class Form {
    submitDisabled(selector) {
        document.getElementById(selector ?? "submit-form").disabled = true;
    }

    removeSubmitDisabled(selector) {
        document.getElementById(selector ?? "submit-form").disabled = false;
    }
}

export default new Form();